.score-control-board{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.row-team-score-buttons{
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 15px ;

  button{
    margin: 10px 0 10px 0;
    width: 100px;
  }
}
.column-team-score-buttons{
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 15px ;

  button{
    margin: 10px 0 10px 0;
    width: 100px;
  }
}

