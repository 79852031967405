.grid-control{
  //display: flex;
  justify-content: center;
  align-content: center;
  text-align: -webkit-center;
}

.custom-button{
  margin: 10px 0 10px 0;
}

