.winners-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  //justify-content: space-between;

  .winner-box {
    //display: flex;
    border-style: solid;
    border-width: 1px;
    width: 50%;
    //margin: 10px;
  }
}

