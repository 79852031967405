.contact-us{
  //display: flex;
  //flex-direction: column;
  //width: 380px;
  //margin: 10px 0;

  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  .contact-us-form{
    //width: 35%;
  }

  .title{
    margin: 10px 0;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
  }
}
