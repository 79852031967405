.grid-players{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .players-list{
    margin-top: 15px;
    width: 50%;
    align-self: center;
  }

  .invite-players{
    width: 50%;
    align-self: center;
  }
}