.reset-password{

  display: block;
  margin: 0 20% 0 20%;

  .alert-success {
    padding: 20px;
    background-color: #04AA6D; /* Green */
    color: white;
    margin-bottom: 15px;
  }

  .alert-danger {
    padding: 20px;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 15px;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
}

