.toggle-container {
    position: relative;
    width: 70px;
    height: 32px;
    background-color: #c4c4c4;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;
    padding: 2px;
    /*margin-top: 130px;*/
    /*margin-left: -150px;*/
}
.toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 38px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    background-color: #FF6666;
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
    padding: 8px 12px;
    border-radius: 20px;
    position: absolute;
    transition: all 0.2s ease;
    left: 34px;
}

.disable {
    background-color: #707070;
    left: 2px;
}

.t1{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000030;
    /*color: $sub-color;*/
    font-size: 12px;
    font-weight: normal;
    /*position: absolute;*/
    pointer-events: none;
    /*left: 5px;*/
    /*top: 10px;*/
}

.emo{
    margin-top: 270px;
    margin-left: -140px;
    font-size: 20px;
}