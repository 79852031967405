.custom-button {
  min-width: 65px;
  width: max-content;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 25px 0 25px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bolder;
  border: 1px solid white;
  cursor: pointer;
  display: flex;
  justify-content: center;


  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;

    &:hover{
      background-color: #357ae8;
      border: none;
    }
  }

  &.inverted{
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover{
      background-color: black;
      color: white;
      border: 1px solid black;
    }
  }
}
