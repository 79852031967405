.home-page{
  display: grid;
  justify-content: center;
  align-content: center;
}

.filters-bar{
  display: flex;
}

.navbar-items{
  display: contents;
}

.filters-bar-element{
  margin-inline: 5px;

  select{
    margin: 5px 0;
  }
  .group{
    margin: 0;
    .form-select {
      margin: 0;
    }
  }
}
