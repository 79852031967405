.th-score{
  min-width: 50px;
}

#score-board-th-team-Left{
  min-width: 100px;
}

#score-board-th-team-Right{
  min-width: 100px;
}

.scoreDiv{
  min-width: 75px;
  align-content: center;
  text-align: center;
}

.smallText{
  font-size: 8pt;
}