.grid-square{
  min-width: 100px;
  min-height: 100px;
  background-color: #ECEFF1;
  display: grid;
  align-content: center;
  justify-items: center;
  //justify-content: center;
  width: 100px;
  overflow: auto;
  font-size: 14px;
  text-transform: capitalize;

  &.paid{
    background-color: greenyellow;
    //opacity: 0.5;
  }

  // START - Keep in order
  &.inRowOrColumn{
    background-color: lightblue;
    //opacity: 0.5;
  }

  &.winnerSquare{
    background-color: lightgoldenrodyellow;
    border-width: medium;
    border-block: inherit;
    border-color: gold;
  }

  &.inRowAndColumn{
    background-color: dodgerblue;
    //opacity: 0.5;
  }
  // END - Keep in order

  &.emptySquareForUser{
    cursor: pointer;
    label{
      cursor: pointer;
    }
  }
  .square-content{
    width: max-content;
    height: max-content;
  }

  button{
    width: fit-content;
    margin: 2px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .grid-square{
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    font-size: 13px;

    button{
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .grid-square{
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    font-size: 12px;

    button{
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .grid-square{
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    font-size: 11px;

    button{
      width: fit-content;
      margin: 2px;
      font-size: 11px;
    }
  }
}
