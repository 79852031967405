.sign-in{
  //width: 380px;
  //display: inline-flex;
  //flex-direction: column;

  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  .sign-in-form{
     //width: 35%;
   }

  .title{
    margin: 10px 0;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
  }

  .alert-danger {
    padding: 20px;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 15px;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
}